body {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  /* font-family: 'Roboto',Arial, Helvetica, sans-serif; */
}
.assetWrapper3 {
  display: flex;
  // width: 100%;
  .sidebar {
    // width: 10%;
    width: 20%;
    padding: 20px;
    position: fixed;
  }
  .sidebarPages {
    width: 80%;
    margin-left: auto;
    padding: 20px 0;
  }
}
