.assetWrapper2 {
  h3 {
    padding: 10px 30px;
    color: #344767;
  }

  display: flex;
  align-items: center;
  .notificationIcon {
    cursor: pointer;
    padding: 10px 50px;
    display: flex;
    align-items: center;
    width: 100%;

    justify-content: flex-end;
  }
}
