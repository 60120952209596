
  .header {
    // height: 45px;
    line-height: 5px;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    display: block;
    margin: 0;
    width: 100%;
    min-width: 1024px;
    // height: 48px;
    // max-height: 44px;
    // background: rgba(255, 255, 255, 0.92);
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 60px;
  }
  

  
  .container {
    width: 100%;
    margin: 7% auto;
  }
  
  .tab-header {
    list-style: none;
    background: rgb(253, 96, 96);
    border-radius: 36px;
    line-height: 70px;
    margin-left: 13%;
    width: 600px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .tab-header li {
    display: inline;
    padding: 7px 12px;
    border-radius: 36px;
    line-height: 35px;
  
    cursor: pointer;
  }
  
  .tab-header li.active,
  .tab-header li:hover {
    background: #ffffff; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #ffffff,
      #ffffff
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #ffffff,
      #ffffff
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  .tab-content {
    height: 400px;
    background: #fff;
    border: 1px solid #dedeed;
    border-radius: 36px;
    margin: 12px;
    width: 100%;
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  }
  
  .tab-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
