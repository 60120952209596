.footerWrapper {
  .footerInnerWrapper {
    margin: 40px 40px 0 40px;
    display: flex;
    justify-content: space-between;
    .poll {
      font-weight: bold;
    }
    .nation {
      font-weight: 500;
      margin: 0 5px;
    }
    .copyright {
      color: #68768e;
    }
    .terms {
      color: #68768e;
      margin: 0 30px;
    }
    .privacy {
      color: #68768e;
    }
  }
}
